import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { postEventApplication } from '../Api';

export default function EventApplication(props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [region, setRegion] = useState('');
  const [regionOthers, setRegionOthers] = useState('');
  const [open_quiz_1, setOpen_quiz_1] = useState([]);
  const [open_quiz_1_other, setOpen_quiz_1_other] = useState('');
  const [open_quiz_2, setOpen_quiz_2] = useState('');
  const [open_quiz_3, setOpen_quiz_3] = useState({
    navigation: '',
    design: '',
    speed: '',
    payment: '',
    support: '',
  });
  const [open_quiz_4, setOpen_quiz_4] = useState('');
  const [open_quiz_5, setOpen_quiz_5] = useState([]);
  const [open_quiz_6, setOpen_quiz_6] = useState('');
  const [open_quiz_7, setOpen_quiz_7] = useState('');
  const [open_quiz_8, setOpen_quiz_8] = useState('');
  const [open_quiz_9, setOpen_quiz_9] = useState('');

  const handleCheckboxChange = (setFunc) => (event) => {
    const { value, checked } = event.target;
    setFunc((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleRadioChange = (setFunc) => (event) => {
    setFunc(event.target.value);
  };

  const handleRatingChange = (field) => (event) => {
    setOpen_quiz_3((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = {
      title: props.title,
      phone: phone,
      name: name,
      residency: region,
      residency_other: regionOthers,
      open_quiz_1: open_quiz_1.includes('Other')
        ? [...open_quiz_1.filter((option) => option !== 'Other'), `Other: ${open_quiz_1_other}`].join(', ')
        : open_quiz_1.join(', '),
      open_quiz_2: open_quiz_2,
      open_quiz_3: JSON.stringify(open_quiz_3),
      open_quiz_4: open_quiz_4,
      open_quiz_5: open_quiz_5.join(', '),
      open_quiz_6: open_quiz_6,
      open_quiz_7: open_quiz_7,
      open_quiz_8: open_quiz_8,
      open_quiz_9: open_quiz_9,
    };

    console.log(data);

    postEventApplication(data).then((msg) => {
      setName('');
      setPhone('');
      setRegion('');
      setRegionOthers('');
      setOpen_quiz_1([]);
      setOpen_quiz_1_other('');
      setOpen_quiz_2('');
      setOpen_quiz_3({
        navigation: '',
        design: '',
        speed: '',
        payment: '',
        support: '',
      });
      setOpen_quiz_4('');
      setOpen_quiz_5([]);
      setOpen_quiz_6('');
      setOpen_quiz_7('');
      setOpen_quiz_8('');
      setOpen_quiz_9('');
      setLoading(false);

      if (msg !== false) {
        setSuccess(true);
      }
    });
  };

  return (
    <div style={{ width: '100%', maxWidth: 800, backgroundColor: !success ? '#e5e5e5' : 'white', padding: 10, borderRadius: 10 }}>
      {success === false && (
        <form onSubmit={handleSubmit}>
          <h4>Participate in the Survey</h4>

          {/* Name */}
          <div className="form-group">
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your full name"
            />
          </div>

          {/* Phone */}
          <div className="form-group">
            <label htmlFor="phone" className="required">
              Phone Number
            </label>
            <input
              required
              type="tel"
              maxLength={13}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              id="phone"
              placeholder="Enter your phone number"
            />
          </div>

          {/* Region */}
          <div className="form-group">
            <label htmlFor="region" className="required">
              Region
            </label>
            <select
              required
              className="form-control"
              id="region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            >
              <option value="">-- --</option>
              {[
                ['Githurai_44/45', 'Githurai 44/45'],
                ['Roysambu/Mirema', 'Roysambu/Mirema'],
                ['Zimmerman', 'Zimmerman'],
                ['Kahawa_West', 'Kahawa West'],
                ['Kahawa_Wendani', 'Kahawa Wendani'],
                ['Kasarani', 'Kasarani'],
                ['Hunters/Mwiki/Sunton', 'Hunters/Mwiki/Sunton'],
                ['Lucky_Summer/Baba_Dogo/Ngumba', 'Lucky Summer/Baba Dogo/Ngumba'],
                ['kariobangi', 'Kariobangi'],
                ['Mowlem_Umoja_3', 'Mowlem/Umoja 3'],
                ['Umoja', 'Umoja'],
                ['Pipeline', 'Pipeline'],
                ['Kibera', 'Kibera'],
              ].map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
              ))}
              <option value="Other">Other</option>
            </select>
          </div>

          {region === 'Other' && (
            <div className="form-group">
              <label htmlFor="regionOthers">
                Specify residency if `Other` is selected
              </label>
              <input
                value={regionOthers}
                onChange={(e) => setRegionOthers(e.target.value)}
                type="text"
                className="form-control"
                id="regionOthers"
                placeholder=""
              />
            </div>
          )}

          {/* Question 1: How did you hear about the app? */}
          <div className="form-group">
            <label className="required">
              1. How did you first hear about the Konnect Super App?
            </label>
            <br />
            {['Social Media (Facebook)', 'Friend/Family Recommendation', 'Konnect staff', 'Other'].map((option) => (
              <div key={option}>
                <input
                  type="checkbox"
                  className="input_test"
                  id={`open_quiz_1_${option.replace(/\s+/g, '_')}`}
                  name="open_quiz_1"
                  value={option}
                  checked={open_quiz_1.includes(option)}
                  onChange={handleCheckboxChange(setOpen_quiz_1)}
                />
                <label htmlFor={`open_quiz_1_${option.replace(/\s+/g, '_')}`}>{option}</label>
                <br />
              </div>
            ))}
            {open_quiz_1.includes('Other') && (
              <input
                type="text"
                className="form-control"
                id="open_quiz_1_other"
                name="open_quiz_1_other"
                value={open_quiz_1_other}
                onChange={(e) => setOpen_quiz_1_other(e.target.value)}
                placeholder="Please specify"
              />
            )}
          </div>

          {/* Question 2: Ease of download and sign-in */}
          <div className="form-group">
            <label className="required">
              2. How easy was it to download and sign into the app? (Rate 1-5, where 1 = Difficult, 5 = Very Easy)
            </label>
            <br />
            <div style={{ display: 'flex', gap: '10px' }}> {/* Flexbox for horizontal layout */}
              {[1, 2, 3, 4, 5].map((rating) => (
                <div key={rating} style={{ display: 'flex', alignItems: 'center' ,marginRight:10}}>
                  <input
                    type="radio"
                    className="input_test"
                    id={`open_quiz_2_${rating}`}
                    name="open_quiz_2"
                    value={rating}
                    checked={open_quiz_2 === rating.toString()}
                    onChange={handleRadioChange(setOpen_quiz_2)}
                  />
                  <label htmlFor={`open_quiz_2_${rating}`} style={{ marginLeft: '5px' }}>{rating}</label>
                </div>
              ))}
            </div>
          </div>

          {/* Question 3: App ratings */}
          <div className="form-group">
            <label className="required">
              3. How would you rate the following aspects of the app? (Rate each from 1-5, where 1 = Poor, 5 = Excellent)
            </label>
            <br />
            {[
              { field: 'navigation', label: 'Ease of navigation' },
              { field: 'design', label: 'Design & appearance' },
              { field: 'speed', label: 'App speed & responsiveness' },
              { field: 'payment', label: 'Payment process experience' },
              { field: 'support', label: 'Customer Support & issue resolution' },
            ].map((item) => (
              <div key={item.field}>
                <label>{item.label}</label>
                <br />
                <div style={{ display: 'flex', gap: '10px' }}> {/* Flexbox for horizontal layout */}
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <div key={rating} style={{ display: 'flex', alignItems: 'center' ,marginRight:10}}>
                      <input
                        type="radio"
                        className="input_test"
                        id={`open_quiz_3_${item.field}_${rating}`}
                        name={`open_quiz_3_${item.field}`}
                        value={rating}
                        checked={open_quiz_3[item.field] === rating.toString()}
                        onChange={handleRatingChange(item.field)}
                      />
                      <label htmlFor={`open_quiz_3_${item.field}_${rating}`} style={{ marginLeft: '5px' }}>{rating}</label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Question 4: Challenges faced */}
          <div className="form-group">
            <label>
              4. Have you encountered any challenge or performance issues while using the app? If yes, please describe.
            </label>
            <textarea
              value={open_quiz_4}
              onChange={(e) => setOpen_quiz_4(e.target.value)}
              className="form-control"
              id="open_quiz_4"
              placeholder="Describe your challenges..."
            />
          </div>

          {/* Question 5: Features used */}
          <div className="form-group">
            <label className="required">
              5. What feature do you use the most in the Konnect Super App? (Select all that apply)
            </label>
            <br />
            {['Connect TV', 'Add Device', 'Save Device', 'Payment', 'Live Chat', "FAQ's"].map((option) => (
              <div key={option}>
                <input
                  type="checkbox"
                  className="input_test"
                  id={`open_quiz_5_${option.replace(/\s+/g, '_')}`}
                  name="open_quiz_5"
                  value={option}
                  checked={open_quiz_5.includes(option)}
                  onChange={handleCheckboxChange(setOpen_quiz_5)}
                />
                <label htmlFor={`open_quiz_5_${option.replace(/\s+/g, '_')}`}>{option}</label>
                <br />
              </div>
            ))}
          </div>

          {/* Question 6: Contacted support? */}
          <div className="form-group">
            <label className="required">
              6. Have you ever contacted customer support through the Live Chat in app?
            </label>
            <br />
            <div style={{ display: 'flex', gap: '10px' }}> {/* Flexbox for horizontal layout */}
              {['Yes', 'No'].map((option) => (
                <div key={option} style={{ display: 'flex', alignItems: 'center' ,marginRight:10}}>
                  <input
                    type="radio"
                    className="input_test"
                    id={`open_quiz_6_${option}`}
                    name="open_quiz_6"
                    value={option}
                    checked={open_quiz_6 === option}
                    onChange={handleRadioChange(setOpen_quiz_6)}
                  />
                  <label htmlFor={`open_quiz_6_${option}`} style={{ marginLeft: '5px' }}>{option}</label>
                </div>
              ))}
            </div>
          </div>

          {/* Question 7: Support satisfaction */}
          {open_quiz_6 === 'Yes' && (
            <div className="form-group">
              <label className="required">
                7. If yes, how satisfied were you with the support experience? (Rate 1-5, where 1 = Very Unsatisfied, 5 = Very Satisfied)
              </label>
              <br />
              <div style={{ display: 'flex', gap: '10px' }}> {/* Flexbox for horizontal layout */}
                {[1, 2, 3, 4, 5].map((rating) => (
                  <div key={rating} style={{ display: 'flex', alignItems: 'center' ,marginRight:10}}>
                    <input
                      type="radio"
                      className="input_test"
                      id={`open_quiz_7_${rating}`}
                      name="open_quiz_7"
                      value={rating}
                      checked={open_quiz_7 === rating.toString()}
                      onChange={handleRadioChange(setOpen_quiz_7)}
                    />
                    <label htmlFor={`open_quiz_7_${rating}`} style={{ marginLeft: '5px' }}>{rating}</label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Question 8: Support improvement suggestions */}
          <div className="form-group">
            <label>
              8. How can we improve the in-app customer support system?
            </label>
            <textarea
              value={open_quiz_8}
              onChange={(e) => setOpen_quiz_8(e.target.value)}
              className="form-control"
              id="open_quiz_8"
              placeholder="Share your suggestions..."
            />
          </div>

          {/* Question 9: Using old app? */}
          <div className="form-group">
            <label className="required">
              9. Are you still using the old Konnect wifi app?
            </label>
            <br />
            <div style={{ display: 'flex', gap: '10px' }}> {/* Flexbox for horizontal layout */}
              {['Yes', 'No'].map((option) => (
                <div key={option} style={{ display: 'flex', alignItems: 'center' ,marginRight:10}}>
                  <input
                    type="radio"
                    className="input_test"
                    id={`open_quiz_9_${option}`}
                    name="open_quiz_9"
                    value={option}
                    checked={open_quiz_9 === option}
                    onChange={handleRadioChange(setOpen_quiz_9)}
                  />
                  <label htmlFor={`open_quiz_9_${option}`} style={{ marginLeft: '5px' }}>{option}</label>
                </div>
              ))}
            </div>
          </div>

          {/* Submit button */}
          <div style={{ float: 'right' }}>
            {loading ? (
              <button type="button" className="btn btn-warning">
                Loading...
              </button>
            ) : (
              <button type="submit" className="btn btn-warning">
                Submit
              </button>
            )}
          </div>
        </form>
      )}

      {success && (
        <div className={`alert alert-success`} role="alert">
          Thank you for taking the time to complete this survey.
        </div>
      )}
    </div>
  );
}

// Add PropTypes validation
EventApplication.propTypes = {
  title: PropTypes.string.isRequired, // Validate that `title` is a required string
};